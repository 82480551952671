import React from "react";
import * as Icon from "react-feather";
import { FormattedMessage } from "react-intl";
import customLabel from "../../src/assets/customArray/label.json";
import { canI, canIArray } from "../authFunctions/functions";

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));
const auth = JSON.parse(localStorage.getItem("auth"));
const companies = JSON.parse(localStorage.getItem("companies"));
const jobs = JSON.parse(localStorage.getItem("jobs"));

const horizontalMenuConfig = [
    canIArray(auth, ["WORLD WIDE SITE","DASHBOARD"])
    ? {}
    : {
        id: "homeDropdown",
        title: <FormattedMessage id="Home" />,
        type: "dropdown",
        icon: <Icon.Home size={15} />,
        children: [
            canIArray(auth, ["WORLD WIDE SITE"])
            ? {}
            : {
                id: "worldwide",
                title: <FormattedMessage id="Worldwide sites" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/map",
              },
            canIArray(auth, ["DASHBOARD"])
            ? {}
            : {
                id: "dashBoard",
                title: <FormattedMessage id="Dashboard" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/dashboard",
              },
        ],
      },
  canIArray(auth,["USERS LIST (all employees?)",
        "USERS ROLE",
        "AUTHORIZATION MATRIX"])
    ? {}
    : {
        id: "usersDropdown",
        title: <FormattedMessage id="Users" />,
        type: "dropdown",
        icon: <Icon.Users size={15} />,
        children: [
          canIArray(auth,["USERS LIST (all employees?)"])
            ? {}
            : {
                id: "usersList",
                title: <FormattedMessage id="Users List" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/users",
              },
          canIArray(auth,["USERS ROLE"])
            ? {}
            : {
                id: "usersRoles",
                title: <FormattedMessage id="Users Roles" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/user-levels",
              },
          canIArray(auth,["AUTHORIZATION MATRIX"])
            ? {}
            : {
                id: "authorization",
                title: <FormattedMessage id="Authorization Matrix" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: token != null ? "/authorization" : "/user/create",
              },
        ],
      },
  canIArray(auth,["SMS COMPANIES",
        "REGIONS",
        "CENTER OF EXCELLENCES",
        "CENTER OF COMPETENCIES",
        "PRODUCTS",
        "DEPARTMENTS",
        "INTERNAL CLUSTERS",
        "INTERNAL COSTS"])
    ? {}
    : {
        id: "listsDropdown",
        title: <FormattedMessage id={customLabel.company} />,
        type: "dropdown",
        icon: <Icon.Tool size={15} />,
        children: [
          canIArray(auth,["SMS COMPANIES"])
            ? {}
            : {
                id: "entities",
                title: <FormattedMessage id={customLabel.entities} />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/entities",
              },
          canIArray(auth,["REGIONS"])
            ? {}
            : {
                id: "regions",
                title: <FormattedMessage id="Regions" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/regions",
              },
          canIArray(auth,["CENTER OF EXCELLENCES"])
            ? {}
            : {
                id: "businessUnits",
                title: <FormattedMessage id={customLabel.coe} />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/center-of-excellences",
              },
          canIArray(auth,["CENTER OF COMPETENCIES"])
            ? {}
            : {
                id: "competences",
                title: <FormattedMessage id={customLabel.coc} />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/center-of-competences",
              },
          canIArray(auth,["PRODUCTS"])
            ? {}
            : {
                id: "products",
                title: <FormattedMessage id="Products" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/prodotti",
                // navLink: "#",
              },
          canIArray(auth,["DEPARTMENTS"])
            ? {}
            : {
                id: "departments",
                title: <FormattedMessage id="Departments" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/departments",
              },
          canIArray(auth,["INTERNAL CLUSTERS"])
            ? {}
            : {
                id: "macroCat",
                title: <FormattedMessage id={customLabel.clusters} />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/macro-categories",
              },
          canIArray(auth,["INTERNAL COSTS"])
            ? {}
            : {
                id: "purchaseOrdersInt",
                title: <FormattedMessage id="Internal Costs" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/purchase-orders/int",
              },
        ],
      },
  canIArray(auth,["CUSTOMERS",
        "CONTRACTORS",
        "SITE PERSONNEL",
        "QUALIFICATIONS (all sub-menu)"])
    ? {}
    : {
        id: "masterData",
        title: <FormattedMessage id="Master Data" />,
        type: "dropdown",
        icon: <Icon.Database size={15} />,
        children: [
          canIArray(auth,["CUSTOMERS"])
            ? {}
            : {
                id: "clients",
                title: <FormattedMessage id="Customers" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/clients",
              },
          canIArray(auth,["CONTRACTORS"])
            ? {}
            : {
                id: "personnelSuppliers",
                title: <FormattedMessage id="Contractors" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/personale-suppliers",
              },
          canIArray(auth,["SITE PERSONNEL"])
            ? {}
            : {
                id: "sitePersonnel",
                title: <FormattedMessage id="Site Personnel" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/personale",
              },
          canIArray(auth,["QUALIFICATIONS (all sub-menu)"])
            ? {}
            : {
                id: "qualificationsDropdown",
                title: <FormattedMessage id="Qualifications" />,
                type: "dropdown",
                icon: <Icon.Database size={15} />,
                children: [
                  {
                    id: "qualificationGroups",
                    title: <FormattedMessage id="Qualification Groups" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/raggruppamenti",
                  },
                  {
                    id: "qualifications",
                    title: <FormattedMessage id="Qualification" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/qualifiche",
                  },
                ],
              },
        ],
      },
  canIArray(auth,["JOBS (List)",
        "JOBS SUPERVISOR",
        "JOBS OPTION SET-UP (all sub-menu)"])
    ? {}
    : {
        id: "jobsDropdown",
        title: <FormattedMessage id="Jobs" />,
        type: "dropdown",
        icon: <Icon.BookOpen size={15} />,
        children: [
          canIArray(auth,["JOBS (List)"])
            ? {}
            : {
                id: "jobs",
                title: <FormattedMessage id="Jobs" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/jobs",
              },
          canIArray(auth,["JOBS SUPERVISOR"])
            ? {}
            : {
                id: "purchaseOrdersExt",
                title: <FormattedMessage id="Purchase Orders" />,
                type: "item",
                //        icon: <Icon.DollarSign size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/purchase-orders/ext",
              },
          canIArray(auth,["PURCHASE ORDERS",
                "MAN/DAYS GRAPHS",
                "JOB WORKLOAD",
                "PERSONNEL WORKLOAD",
                "QUALIFICATION PLANNING",
                "MAN/DAY DETAILS",
                "PHASE CATEGORY GRAPH",
                "CASH REPORTS (all sub-menu)"])
            ? {}
            : {
                id: "jobsDataOptions",
                title: <FormattedMessage id="Jobs Options Set-up" />,
                type: "dropdown",
                icon: <Icon.Settings size={15} />,
                children: [
                  /* {
            id: "servicesToBeInvoiced",
            title: <FormattedMessage id="Contractual Services" />,
            type: "item",
            icon: <Icon.List size={15} />,
            permissions: ["admin", "editor"],
            navLink: "/inv-services",
          }, */
                  {
                    id: "calendars",
                    title: <FormattedMessage id="Calendars" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/calendars",
                  },
                  {
                    id: "rotations",
                    title: <FormattedMessage id="Rotations" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/rotations",
                  },
                  {
                    id: "visaTypes",
                    title: <FormattedMessage id="VISA" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/visa-types",
                  },
                  {
                    id: "tipoSpese",
                    title: <FormattedMessage id="Expenses Type" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/tipo-spese",
                  },
                  {
                    id: "currencies",
                    title: <FormattedMessage id="Currencies" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/valute",
                  },
                  {
                    id: "phaseType",
                    title: <FormattedMessage id="Phase Type" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/tipi-fase",
                  },
                ],
              },
        ],
      },

canIArray(auth,["INVOICES LIST",
            "PRE-INVOICES",
            "MONTLY WORKING DAYS"])
    ? {}
    : {
        id: "invoicesDropdown",
        title: <FormattedMessage id="Invoices" />,
        type: "dropdown",
        icon: <Icon.DollarSign size={15} />,
        children: [
          canIArray(auth,["INVOICES LIST"])
            ? {}
            : {
                id: "invoicesList",
                title: <FormattedMessage id="Invoices list" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/invoices",
              },
          process.env.REACT_APP_PRE_INVOICES_MODULE !== true && 
          canIArray(auth,["PRE-INVOICES"])
            ? {}
            : {
                id: "preInvoices",
                title: <FormattedMessage id="Pre-Invoices" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/pre-invoices",
              },
          process.env.REACT_APP_MONTHLY_WORKING_DAYS === true &&
          canIArray(auth,["MONTLY WORKING DAYS"])
            ? {}
            : {
                id: "statino",
                title: <FormattedMessage id="Monthly Working Days" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/statino",
              },
        ],
      },
  canIArray(auth,["MAN/DAYS GRAPHS",
        "JOB WORKLOAD",
        "PERSONNEL WORKLOAD",
        "QUALIFICATION PLANNING",
        "MAN/DAY DETAILS",
        "PHASE CATEGORY GRAPH",
        "CASH REPORTS (all sub-menu)"])
    ? {}
    : {
        id: "reports",
        title: <FormattedMessage id="Reports" />,
        type: "dropdown",
        icon: <Icon.BarChart2 size={15} />,
        children: [
          canIArray(auth,["MAN/DAYS GRAPHS"])
            ? {}
            : {
                id: "totalsGraph",
                title: <FormattedMessage id="Man/Days Graphs" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/totalgraphs",
              },
          canIArray(auth,["JOB WORKLOAD"])
            ? {}
            : {
                id: "jobWorkload",
                title: <FormattedMessage id="Job Workload" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/workload",
              },
          canIArray(auth,["PERSONNEL WORKLOAD"])
            ? {}
            : {
                id: "JobsSupervisor",
                title: <FormattedMessage id="Jobs Supervisor" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/jobs-supervisor",
              },
          canIArray(auth,["JOBS OPTION SET-UP (all sub-menu)"])
            ? {}
            : {
                id: "personnelWorkload",
                title: <FormattedMessage id="Personnel Workload" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/personnelworkload",
              },
          canIArray(auth,["QUALIFICATION PLANNING"])
            ? {}
            : {
                id: "qualificationPlanning",
                title: <FormattedMessage id="Qualification Planning" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/qualificationPlanning",
              },
          canIArray(auth,["MAN/DAY DETAILS"])
            ? {}
            : {
                id: "manDayDetails",
                title: <FormattedMessage id="Man/Day Details" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/manDayDetails",
              },
          canIArray(auth,["PHASE CATEGORY GRAPH"])
            ? {}
            : {
                id: "phaseCategoryGraph",
                title: <FormattedMessage id="Phase Category Graph" />,
                type: "item",
                // icon: <Icon.List size={15} />,
                permissions: ["admin", "editor"],
                navLink: "/reports/phaseCategoryGraph",
              },
          canIArray(auth,["CASH REPORTS (all sub-menu)"])
            ? {}
            : {
                id: "cashReports",
                title: <FormattedMessage id="Cash Reports" />,
                type: "dropdown",
                icon: <Icon.BarChart2 size={15} />,
                children: [
                  {
                    id: "cashOutReports",
                    title: <FormattedMessage id="Costs Details" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/reports/cashOut",
                  },
                  {
                    id: "cashInReports",
                    title: <FormattedMessage id="Incomes Details" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/reports/cashIn",
                  },
                  {
                    id: "cashFlow",
                    title: <FormattedMessage id="Balance Sheet" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/reports/cashFlow",
                  },
            process.env.REACT_APP_PROJECT_YEAR_VALUES !== true
            ? {}
            :     {
                    id: "prjYearValues",
                    title: <FormattedMessage id="Project Year Values" />,
                    type: "item",
                    // icon: <Icon.List size={15} />,
                    permissions: ["admin", "editor"],
                    navLink: "/reports/prjYearValues",
                  },
                ],
              },
        ],
      },
];

export default horizontalMenuConfig;
