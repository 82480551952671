import React from "react"
import ScrollToTop from "react-scroll-up"
import { Button } from "reactstrap"
import { Heart, ArrowUp } from "react-feather"
import classnames from "classnames"
import FooterLogo from "../../../../src/assets/img/dashboard/tech-evolution.png"

const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"]
  const year = new Date().getFullYear();
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden"
      })}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25" style={{fontSize: "0.8em"}}>
          Copyright ©️ 2018 - { year } 
          <a
            href="http://tech-engineering.it/"
            target="_blank"
            rel="noopener noreferrer"
          >
           Tech-evolution srl 
          </a>
          All rights reserved
        </span>
        <span className="float-md-right d-none d-md-block" style={{ textAlign: "right", fontSize: "0.8em"}}>
          <span className="align-right">Hand-crafted & Made by</span>{" "}
          {/* <Heart className="text-danger" size={15} /> */}
          <img src={FooterLogo} width="20%" style={{ padding: "6px 8px"}}></img>
        </span>
      </p>
      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
  )
}

export default Footer
