export const canI = (auth) => {
  var iCan = true;
  if (auth === 'None' || auth === 'N/A') {
    iCan = false;
  }
  return iCan;
};

export const canIAll = (auth) => {
  var iCan = false;
  if (auth === 'All') {
    iCan = true;
  }
  return iCan;
};

export const canICompany = (auth) => {
  var iCanCompany = false;
  if (auth === 'Own') {
    iCanCompany = true;
  }
  return iCanCompany;
};

export const canIProject = (auth) => {
  var iCanProject = false;
  if (auth === 'Project') {
    iCanProject = true;
  }
  return iCanProject;
};

export const canIArray = (auth, list) => {
    let disArr = ['None', 'N/A'];
    return (auth && 
        typeof auth === 'object' &&
        list.every(element => (
            (element in auth) ? (
                    disArr.includes(auth[element].ReadOpzione ?? '') &&  
                    disArr.includes(auth[element].EditOpzione ?? '')
                    )
                :
                    false 
                )
            )
        );
};

//            auth.constructor === Array &&

//    console.log('typeof auth'+(typeof auth));
//    console.log('constructor auth'+(auth.constructor === Array));
//        && auth.constructor === Array) 
//    if(auth && typeof auth === 'object') {            
//            let currEvery = list.every(element => (
//                        disArr.includes(auth[element].ReadOpzione ?? '') &&  
//                        disArr.includes(auth[element].EditOpzione ?? '')
//                        ));
//            return currEvery;    
//        } else {
//            return false;
//        }
//    
