import React from "react"
import { IntlProvider } from "react-intl"

import messages_en from "../assets/data/locales/en.json"
//import messages_de from "../assets/data/locales/de.json"
//import messages_fr from "../assets/data/locales/fr.json"
//import messages_pt from "../assets/data/locales/pt.json"
import messages_it from "../assets/data/locales/it.json"

const messages_list = {
  en: messages_en,
//  de: messages_de,
//  fr: messages_fr,
//  pt: messages_pt
  it: messages_it
}

const IntlContext = React.createContext()

class IntlProviderWrapper extends React.Component {
  state = {
    locale: "en",
    messages: messages_list["en"]
  }

  render() {
    const { children } = this.props
    const { locale, messages } = this.state
    return (
      <IntlContext.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            this.setState({
              locale: language,
              messages: messages_list[language]
            })
          }
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </IntlContext.Provider>
    )
  }
}

//export { IntlProviderWrapper, Context as IntlContext }
export { IntlProviderWrapper, IntlContext }
